import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Heading, LinkButton } from "gatsby-interface"
import { gradientHeadingCss } from "./styles"
import { consultationFeatures } from "./data"
import { FeatureGrid } from "./FeatureGrid"
import { FeatureItem } from "./FeatureItem"
import { contentPositionerCss, ctaBtnCss } from "../shared/styles"
import { MdArrowDownward } from "react-icons/md"

const rootCss = theme => [
  contentPositionerCss({ theme }),
  {
    paddingLeft: 0,
    paddingRight: 0,

    [theme.mediaQueries.desktop]: {
      paddingLeft: theme.space[8],
      paddingRight: theme.space[8],
    },
  },
]

const innerCss = theme => [
  {
    alignItems: `center`,
    display: `flex`,
    flexDirection: `column`,
    padding: `${theme.space[11]} ${theme.space[5]} ${theme.space[13]}`,
    textAlign: `center`,
    position: `relative`,
    zIndex: 0,
    borderRadius: theme.radii[3],

    [theme.mediaQueries.desktop]: {
      padding: `${theme.space[13]} ${theme.space[10]} ${theme.space[15]}`,
      borderRadius: theme.radii[4],
    },
  },
]

const headingCss = theme => [
  gradientHeadingCss({ theme, gradientDirection: 180 }),
  {
    fontSize: theme.fontSizes[10],
    letterSpacing: theme.letterSpacings.tight,
    textAlign: `center`,
    marginTop: theme.space[8],

    [theme.mediaQueries.desktop]: {
      fontSize: theme.fontSizes[12],
    },
  },
]

const deemphasizedHeadingCss = theme => ({
  color: theme.colors.blackFade[80],
  fontSize: theme.fontSizes[5],
  letterSpacing: theme.letterSpacings.tight,
  textAlign: `center`,
  maxWidth: `inherit`,

  [theme.mediaQueries.desktop]: {
    fontSize: theme.fontSizes[6],
    maxWidth: `30ch`,
    textAlign: `left`,
  },
})

const teamCss = theme => ({
  display: `grid`,
  gridTemplateColumns: `1fr`,
  gridGap: theme.space[4],
  alignItems: `center`,
  marginBottom: `13ch`,

  [theme.mediaQueries.desktop]: {
    gridTemplateColumns: `1fr 1fr`,
  },
})

const teamDescriptionCss = theme => ({
  marginLeft: theme.space[9],
  marginRight: theme.space[9],
})

const teamNoteCss = theme => ({
  color: theme.colors.grey[70],
  fontSize: theme.fontSizes[1],
  margin: 0,
  marginTop: theme.space[4],
  textAlign: `center`,

  [theme.mediaQueries.desktop]: {
    textAlign: `left`,
  },
})

const subtitleCss = theme => ({
  color: theme.colors.grey[90],
  fontSize: theme.fontSizes[3],
  marginTop: theme.space[4],
  textAlign: `center`,
})

const actionsCss = theme => ({
  display: `flex`,
  flexDirection: `column`,
  marginTop: theme.space[9],
  marginBottom: theme.space[0],

  "& > :first-child": {
    marginBottom: theme.space[0],
  },

  [theme.mediaQueries.phablet]: {
    flexDirection: `row`,

    "& > :first-child": {
      marginBottom: 0,
      marginRight: theme.space[4],
    },
  },
})

export function ExpertConsultation() {
  const { teamImage } = useStaticQuery(graphql`
    query {
      teamImage: file(
        extension: { eq: "png" }
        sourceInstanceName: { eq: "pictures" }
        name: { regex: "/concierge-page_team-bubble/" }
      ) {
        id
        childImageSharp {
          id
          fluid(maxWidth: 550) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <div css={rootCss}>
      <div css={innerCss}>
        <div css={teamCss}>
          <div>
            <Img
              fluid={teamImage.childImageSharp.fluid}
              alt="Gatsby team alongside high scoring lighthouse scores"
            />
          </div>
          <div css={teamDescriptionCss}>
            <Heading as="h2" css={deemphasizedHeadingCss} variant="EMPHASIZED">
              When Gatsby Is Critical To Your Success.{" "}
              <span css={{ color: `#663399` }}>
                Our Concierge Program Is Here To Help.
              </span>
            </Heading>
            <p css={teamNoteCss}>
              Partner with our team to optimize your Gatsby site performance.
              The Gatsby Concierge Team will conduct deep-dives into your build,
              run-time, or migration issues to ensure the performance of your
              site. The Gatsby Support Team will efficiently help you resolve
              any production issues with your site.
            </p>
            <p css={teamNoteCss}>
              Our Concierge and Support teams combine our extensive Gatsby
              optimization, implementation and operations knowledge with market
              and development expertise. Your team will be confident and
              successful when building and deploying Gatsby when supported by
              our support and services experts.
              <div css={actionsCss}>
                <LinkButton
                  href="#contact"
                  size="XL"
                  css={theme => ctaBtnCss({ theme, tone: `cloud` })}
                  rightIcon={<MdArrowDownward />}
                >
                  Contact Gatsby Now
                </LinkButton>
              </div>
            </p>
          </div>
        </div>
        <Heading as="h2" css={headingCss} variant="EMPHASIZED">
          Our Concierge Offerings
        </Heading>
        <p css={subtitleCss}>
          Get hands-on guidance and expertise for high-traffic, mission-critical
          websites and applications.
        </p>
        <FeatureGrid>
          {consultationFeatures.map((feature, index) => (
            <FeatureItem key={index} feature={feature}>
              {feature.emText}
            </FeatureItem>
          ))}
        </FeatureGrid>
      </div>
    </div>
  )
}
