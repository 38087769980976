import React from "react"
import { Heading, LinkButton } from "gatsby-interface"
import { MdArrowDownward } from "react-icons/md"
import { contentPositionerCss, ctaBtnCss } from "../shared/styles"
import { gradientHeadingCss } from "./styles"

import leftBackground from "../../assets/backgrounds/concierge-page_header-a.svg"
import rightBackground from "../../assets/backgrounds/concierge-page_header-b.svg"
import bottomBackground from "../../assets/backgrounds/concierge-page_header-c.svg"

const rootCss = theme => ({
  background: `url(${bottomBackground}) repeat-x center bottom`,
  paddingBottom: theme.space[9],
  marginBottom: theme.space[9],

  [theme.mediaQueries.desktop]: {
    background: `url(${leftBackground}) no-repeat left top, url(${rightBackground}) no-repeat right top`,
  },
})

const headerCss = theme => [
  contentPositionerCss({ theme }),
  {
    alignItems: `center`,
    display: `flex`,
    flexDirection: `column`,
    justifyContent: `center`,
    letterSpacing: theme.letterSpacings.tight,
    textAlign: `center`,
  },
]

const preHeadingCss = theme => [
  gradientHeadingCss({ theme }),
  {
    textTransform: `uppercase`,
    fontSize: theme.fontSizes[2],
    fontWeight: theme.fontWeights.semiBold,
    marginBottom: theme.space[5],
    letterSpacing: theme.letterSpacings.tracked,

    [theme.mediaQueries.tablet]: {
      fontSize: theme.fontSizes[3],
    },
  },
]

const headingCss = theme => [
  gradientHeadingCss({ theme }),
  {
    fontSize: theme.fontSizes[10],
    maxWidth: `18ch`,

    [theme.mediaQueries.tablet]: {
      span: {
        display: `block`,
      },
    },

    [theme.mediaQueries.desktop]: {
      fontSize: theme.fontSizes[15],
    },
  },
]

const ledeCss = theme => ({
  color: theme.colors.grey[70],
  fontSize: theme.fontSizes[2],
  margin: 0,
  marginTop: theme.space[9],
  maxWidth: `40rem`,

  span: {
    color: theme.colors.blue[60],
  },

  [theme.mediaQueries.desktop]: {
    fontSize: theme.fontSizes[4],
  },
})

const actionsCss = theme => ({
  display: `flex`,
  flexDirection: `column`,
  marginTop: theme.space[9],
  marginBottom: `0ch`,
  padddingBottom: `0ch`,

  "& > :first-child": {
    marginBottom: theme.space[0],
  },

  [theme.mediaQueries.phablet]: {
    flexDirection: `row`,

    "& > :first-child": {
      marginBottom: 0,
      marginRight: theme.space[4],
    },
  },
})

export function PageHeader() {
  return (
    <div css={rootCss}>
      <header css={headerCss}>
        <p css={preHeadingCss}>Support & Services</p>
        <Heading as="h1" css={headingCss} variant="EMPHASIZED">
          Concierge Bundles For An Optimal Gatsby Experience
        </Heading>
        <p css={ledeCss}>
          Optimize your site performance and ensure a smooth production
          experience with our Support and Services bundles
        </p>
        <div css={actionsCss}>
          <LinkButton
            href="#contact"
            size="XL"
            css={theme => ctaBtnCss({ theme, tone: `cloud` })}
            rightIcon={<MdArrowDownward />}
          >
            Contact Us
          </LinkButton>
        </div>
      </header>
    </div>
  )
}
