import React from "react"
import { Heading } from "gatsby-interface"
import { supportFeatures } from "./data"
import { FeatureGrid } from "./FeatureGrid"
import { FeatureItem } from "./FeatureItem"
import { contentPositionerCss } from "../shared/styles"

const rootCss = theme => [
  contentPositionerCss({ theme }),
  {
    paddingLeft: 0,
    paddingRight: 0,

    [theme.mediaQueries.desktop]: {
      paddingLeft: theme.space[8],
      paddingRight: theme.space[8],
    },
  },
]

const innerCss = theme => [
  {
    alignItems: `center`,
    display: `flex`,
    flexDirection: `column`,
    padding: `0 ${theme.space[5]} ${theme.space[13]}`,
    textAlign: `center`,
    position: `relative`,
    zIndex: 0,

    [theme.mediaQueries.desktop]: {
      padding: `0 ${theme.space[10]} ${theme.space[15]}`,
      borderRadius: theme.radii[4],
    },
  },
]

const deemphasizedHeadingCss = theme => ({
  color: theme.colors.blackFade[80],
  fontSize: theme.fontSizes[5],
  letterSpacing: theme.letterSpacings.tight,
  textAlign: `center`,
  maxWidth: `inherit`,

  [theme.mediaQueries.desktop]: {
    fontSize: theme.fontSizes[6],
    maxWidth: `30ch`,
    textAlign: `left`,
  },
})

export function DedicatedSupport() {
  return (
    <div css={rootCss}>
      <div css={innerCss}>
        <Heading
          as="h3"
          css={deemphasizedHeadingCss}
          variant="EMPHASIZED"
        ></Heading>
        <FeatureGrid>
          {supportFeatures.map((feature, index) => (
            <FeatureItem key={index} feature={feature} breakTitle={true}>
              {feature.emText}
            </FeatureItem>
          ))}
        </FeatureGrid>
      </div>
    </div>
  )
}
