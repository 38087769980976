import TrainingIcon from "../../assets/concierge-training.svg"
import AuditIcon from "../../assets/concierge-audit.svg"
import ReviewIcon from "../../assets/concierge-review.svg"
import AccountIcon from "../../assets/concierge-account-management.svg"
import SlackIcon from "../../assets/concierge-slack-channel.svg"
import TicketIcon from "../../assets/concierge-ticket-prioritization.svg"
import CoryMortimer from "../../assets/testimonialPhotos/cory-mortimer.jpg"
import AndrewSmith from "../../assets/testimonialPhotos/andrew-smith.jpg"
import LittleCaesarsBackground from "../../assets/backgrounds/little-caesars-background.jpg"
import HousecallBackground from "../../assets/backgrounds/housecall-background.jpg"

import { HousecallProLogo } from "../shared/logos/HousecallProLogo"
import { LittleCaesarsLogo } from "../shared/logos/LittleCaesarsLogo"

export const consultationFeatures = [
  {
    title: `Dedicated`,
    emTitle: `Account Management`,
    text: `Your personal Gatsby guide is dedicated to making sure you’re successful.`,
    icon: AccountIcon,
  },
  {
    title: `Support`,
    emTitle: `Slack Channel`,
    text: `Keep your team on the same page. Get your questions answered quickly.`,
    icon: SlackIcon,
  },
  {
    title: `Support`,
    emTitle: `SLAs`,
    text: `You can be assured that your tickets will be responded to in a prompt manner.`,
    icon: TicketIcon,
  },
]

export const supportFeatures = [
  {
    title: `Premium`,
    emTitle: `Support`,
    text: `Get the assistance you need to run Gatsby confidently in production with our expert support team`,
    icon: TrainingIcon,
  },
  {
    title: `Performance & Build`,
    emTitle: `Optimizations`,
    text: `Get front-end and build audits for deep-dive discovery, then plans to make high-impact changes`,
    icon: AuditIcon,
  },
  {
    title: `Onboarding & Migration`,
    emTitle: `Services`,
    text: ` Hands-on, consultative services to assist in implementation, onboarding, architecture design, and more`,
    icon: ReviewIcon,
  },
]

export const customerQuotes = {
  littleCaesars: {
    Logo: LittleCaesarsLogo,
    text: `Traffic spiked immediately after our Super Bowl ad aired with a high number of simultaneous uses — and we just didn’t have to worry. Because we built with Gatsby, we knew we had the performance to handle the traffic without even thinking about it.`,
    citation: `Andrew Smith`,
    citationTitle: `Application Architect`,
    citationCompany: `Little Caesars`,
    citationAvatar: AndrewSmith,
    background: LittleCaesarsBackground,
  },
  housecallPro: {
    Logo: HousecallProLogo,
    text: `We needed our site to be blazing fast, scalable, have top notch SEO and allow content creators to easily publish new pages. Gatsby delivered all of this, with results: Six months after implementing Gatsby we have increased our organic blog traffic by 973%!`,
    citation: `Cory Mortimer`,
    citationTitle: `Software Engineer`,
    citationCompany: `Housecall Pro`,
    citationAvatar: CoryMortimer,
    background: HousecallBackground,
  },
}
