import React from "react"
import { Heading } from "gatsby-interface"

import { textLinearGradient } from "./styles"

const rootCss = theme => ({
  display: `flex`,
  flexDirection: `column`,
  alignItems: `center`,
  marginTop: theme.space[7],
})

const emTitleCss = theme => ({
  ...textLinearGradient({ theme, gradientDirection: 180 }),
})

const titleCss = theme => ({
  color: theme.colors.grey[90],
  marginBottom: theme.space[2],
})

const textCss = theme => ({
  color: theme.colors.grey[70],
  fontSize: theme.fontSizes[1],
  marginTop: theme.space[1],
  maxWidth: `48ch`,
})

const iconCss = theme => ({
  marginBottom: theme.space[3],
})

export function FeatureItem({ feature, breakTitle = false }) {
  const { icon, title, emTitle, text } = feature
  return (
    <div css={rootCss}>
      <div>
        <img
          src={icon}
          alt={`${title} ${emTitle} icon`}
          css={iconCss}
          role="presentation"
        />
      </div>
      <Heading css={titleCss} as="h3">
        {title} {breakTitle && <br />} <span css={emTitleCss}>{emTitle}</span>
      </Heading>
      <p css={textCss}>{text}</p>
    </div>
  )
}
