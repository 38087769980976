import React from "react"
import { Heading, useTheme } from "gatsby-interface"
import { headingCss } from "./styles"
import HubspotForm from "../hubspot-form"
import { contentPositionerCss } from "../shared/styles"
import leftBackground from "../../assets/backgrounds/concierge-form-bg-left-center.svg"
import rightBackground from "../../assets/backgrounds/concierge-form-bg-right-center.svg"
import bottomBackground from "../../assets/backgrounds/concierge-form-bg-left-bottom.svg"

const rootCss = theme => [
  contentPositionerCss({ theme }),
  {
    paddingLeft: 0,
    paddingRight: 0,

    [theme.mediaQueries.desktop]: {
      paddingLeft: theme.space[8],
      paddingRight: theme.space[8],
    },
  },
]

const preHeadingCss = theme => ({
  color: theme.colors.blackFade[50],
  textTransform: `uppercase`,
  fontSize: theme.fontSizes[2],
  fontWeight: theme.fontWeights.semiBold,
  marginTop: theme.space[4],
  marginBottom: 0,
  letterSpacing: theme.letterSpacings.tracked,

  [theme.mediaQueries.tablet]: {
    fontSize: theme.fontSizes[3],
  },
})

const innerCss = theme => [
  {
    alignItems: `center`,
    background: `url(${leftBackground}) no-repeat left center, url(${rightBackground}) no-repeat right center, url(${bottomBackground}) no-repeat left bottom, linear-gradient(0deg, ${theme.colors.magenta[10]}, ${theme.colors.magenta[5]} 80%)`,
    display: `flex`,
    flexDirection: `column`,
    padding: `6rem ${theme.space[5]} ${theme.space[13]}`,
    textAlign: `center`,
    position: `relative`,
    zIndex: 0,
    borderRadius: theme.radii[3],

    [theme.mediaQueries.desktop]: {
      padding: `7rem ${theme.space[10]} ${theme.space[15]}`,
      borderRadius: theme.radii[4],
    },
  },
]

const subtitleCss = theme => ({
  color: theme.colors.grey[90],
  fontSize: theme.fontSizes[3],
  marginTop: theme.space[4],
  maxWidth: "30rem",
  textAlign: `center`,
})

export function ContactForm() {
  const theme = useTheme()

  return (
    <div css={rootCss}>
      <div css={innerCss}>
        <p css={preHeadingCss}>Let’s chat</p>
        <Heading id="contact" as="h2" css={headingCss} variant="EMPHASIZED">
          Learn More About Concierge Bundles Today
        </Heading>
        <p css={subtitleCss}>
          Let’s schedule a time to discuss your goals and challenges and how
          Gatsby Concierge Bundles can ensure your success. Simply fill out the
          form below and we will be in contact shortly.
        </p>
        <HubspotForm
          portalId="4731712"
          formId="3715a011-4404-4b3c-9823-6011d0ba7486"
          wrapperCss={{
            "& .hs-form-iframe": {
              width: `300px !important`,
            },
            [theme.mediaQueries.desktop]: {
              "& .hs-form-iframe": {
                width: `600px !important`,
              },
            },
            textAlign: `left`,
          }}
        />
      </div>
    </div>
  )
}
