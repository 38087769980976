import React from "react"

const rootCss = theme => ({
  width: `100%`,
  display: `grid`,
  gridTemplateColumns: `1fr`,
  gridGap: theme.space[8],

  [theme.mediaQueries.desktop]: {
    fontSize: theme.fontSizes[6],
    gridTemplateColumns: `1fr 1fr 1fr`,
  },
})

export function FeatureGrid({ children }) {
  return <div css={rootCss}>{children}</div>
}
