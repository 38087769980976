import React from "react"

import { CustomPageLayout } from "../CustomPageLayout"

import { PageHeader } from "./PageHeader"
import { ExpertConsultation } from "./ExpertConsultation"
import { DedicatedSupport } from "./DedicatedSupport"
import { ContactForm } from "./ContactForm"

const rootCss = theme => ({
  display: `grid`,
  gridTemplateColumns: `minmax(0, 1fr)`,
  marginTop: theme.space[10],
})

export function ConciergePage({ seo, location }) {
  return (
    <CustomPageLayout seo={seo} location={location}>
      <div css={rootCss}>
        <PageHeader />
        <DedicatedSupport />
        <ExpertConsultation />
        <ContactForm />
      </div>
    </CustomPageLayout>
  )
}
